import PropTypes from 'prop-types';

export const companyShape = PropTypes.shape({
  id: PropTypes.number,
  company_message: PropTypes.string,
  footer_message: PropTypes.string,
  has_bmm_customer_support_email_reminder: PropTypes.bool,
});

export const partnerShape = PropTypes.shape({
  logoUrl: PropTypes.string,
  description_wrapped_in_font_size: PropTypes.string,
  name: PropTypes.string,
});

export const questionShape = PropTypes.shape({
  id: PropTypes.number,
  question_type: PropTypes.string,
  question: PropTypes.string,
});

export const goalShape = PropTypes.shape({
  id: PropTypes.number,
  length: PropTypes.shape,
  saved: PropTypes.number,
  target: PropTypes.number,
});

export const articleShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
});

export const stressEventShape = PropTypes.shape({
  type: PropTypes.number,
  description: PropTypes.string,
  level: PropTypes.number,
});

const partnerCategoryShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const partnerCompanyShape = PropTypes.shape({
  description: PropTypes.string,
  description_font_size: PropTypes.string,
  description_wrapped_in_font_size: PropTypes.string,
  has_legal_disclosure: PropTypes.bool,
  id: PropTypes.number,
  legal_disclosure: PropTypes.string,
  logo: PropTypes.string,
  logo_relative_path: PropTypes.string,
  name: PropTypes.string,
  updated_at: PropTypes.string,
  url: PropTypes.string,
  utm_url: PropTypes.string,
  _embeded: PropTypes.shape({
    partner_category: PropTypes.shape(partnerCategoryShape),
  }),
});

partnerCategoryShape._embedded = PropTypes.arrayOf(partnerCompanyShape);

const premiumAlertsShape = PropTypes.shape({
  id: PropTypes.number,
  alert: PropTypes.string,
  _embedded: PropTypes.shape({
    partner_companies: PropTypes.arrayOf(partnerCompanyShape),
  }),
});

export { partnerCompanyShape, partnerCategoryShape, premiumAlertsShape };
